import {
  AppstoreAddOutlined,
  DeleteOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Space,
  Input,
  Checkbox,
  Form,
  DatePicker,
  Row,
  Select,
  message,
  Spin,
  Modal,
  Badge,
  Tag,
  Tooltip,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import AppButton from '../../components/AppButton';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { generateQueryString } from '../../helpers/generateQueryString';
import {
  BaseResponsePaginationProps,
  BaseResponseProps,
} from '../../types/config.type';
import { LocationProps } from '../../types/location.type';
import { initialMenu, MenuProps } from '../../types/menu.type';
import { ContainerFilter } from '../admin';
import AppLayout from '../layout/AppLayout';
import moment, { Moment } from 'moment';
import { DeliveryCoverageProperties } from '../../types/delivery-coverage.type';
import {
  ScheduleDeliveryMenuProperties,
  ScheduleDeliveryProperties,
  ScheduleProps,
} from '../../types/schedule.type';
import _ from 'lodash';
import { Trash } from 'react-feather';

interface IParams {
  scheduleAt: string;
}

interface ILocation {
  scheduleAt: string;
}

const dateFormat = 'YYYY-MM-DD';

const { RangePicker } = DatePicker;

const EditSchedule2: React.FC = () => {
  const history = useHistory();
  const location = useLocation<ILocation>();
  const isDetail = location.pathname.includes('detail');

  const { scheduleAt } = useParams<IParams>();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [isLoadingFetchAllMenu, setIsLoadingFetchAllMenu] =
    useState<boolean>(false);
  const [isLoadingCurrentSchedule, setIsLoadingCurrentSchedule] =
    useState<boolean>(false);
  const [isLoadingScheduleDelivery, setIsLoadingScheduleDelivery] =
    useState<boolean>(false);
  const [allMenus, setAllMenus] = useState<MenuProps[]>([]);
  const [search, setSearch] = useState<string>('');
  const [isSelectAllDelivery, setIsSelectAllDelivery] =
    useState<boolean>(false);
  const [isIndeterminateDelivery, setIsIndeterminateDelivery] =
    useState<boolean>(false);
  const [isSelectAllPickupLocation, setIsSelectAllPickupLocation] =
    useState<boolean>(false);
  const [isIndeterminatePickupLocation, setIsIndeterminatePickupLocation] =
    useState<boolean>(false);
  const [allLocations, setAllLocations] = useState<LocationProps[]>([]);
  const [dataSchedulesTmp, setDataSchedulesTmp] = useState<DataRowsType[]>([]);
  const [dataSchedules, setDataSchedules] = useState<DataRowsType[]>([]);
  const [isLoadingDeliveryCoverage, setIsLoadingDeliveryCoverage] =
    useState<boolean>(false);
  const [allDeliveryCoverages, setAllDeliveryCoverages] = useState<
    DeliveryCoverageProperties[]
  >([]);
  const [allDeliveryCoveragesTmp, setAllDeliveryCoveragesTmp] = useState<
    { value: string; label: string }[]
  >([]);
  const [selectedDeliveryCoverageTmp, setSelectedDeliveryCoverageTmp] =
    useState<string>('');
  const [dateRange, setDateRange] = useState<any[]>([undefined, undefined]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [currentScheduleDelivery, setCurrentScheduleDelivery] =
    useState<ScheduleDeliveryProperties>();
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);

  async function handleSubmit() {
    setIsLoadingSubmit(true);
    try {
      let schedules: ScheduleProps[] = [];
      for (const menu of dataSchedules) {
        for (const location of menu.availabilityLocations) {
          if (location.isSelected) {
            schedules.push({
              menuId: menu.menuId,
              scheduleAt,
              locationId: location.locationId,
            });
          }
        }
      }

      await httpRequest.put('/schedules/' + scheduleAt, { schedules });

      const scheduleDelivery = {
        scheduleAt,
        deliveryRangeStartAt: scheduleAt,
        deliveryRangeEndAt: moment(dateRange[1]).format('YYYY-MM-DD'),
        deliveryCoverageId: selectedDeliveryCoverageTmp,
      };

      if (isEdit && currentScheduleDelivery) {
        await httpRequest.patch<any>(
          '/schedule-deliveries/' + currentScheduleDelivery?.scheduleDeliveryId,
          {
            ...scheduleDelivery,
          }
        );

        const scheduleDeliverymenu = dataSchedules
          .filter((item) => item.isDelivery && item)
          .map((item) => ({
            scheduleDeliveryId: currentScheduleDelivery?.scheduleDeliveryId,
            menuId: item.menuId,
          }));

        await httpRequest.patch<any>(
          '/schedule-delivery-menus/menu/bulk/' +
            currentScheduleDelivery?.scheduleDeliveryId,
          {
            bulk: scheduleDeliverymenu,
          }
        );

        message.success('Success update schedule');
      } else {
        const res = await httpRequest.post<any>('/schedule-deliveries/', {
          ...scheduleDelivery,
        });

        if (res && res.data.payload) {
          const scheduleDeliverymenu = dataSchedules
            .filter((item) => item.isDelivery && item)
            .map((item) => ({
              scheduleDeliveryId: res.data.payload.scheduleDeliveryId,
              menuId: item.menuId,
            }));

          await httpRequest.post<any>('/schedule-delivery-menus/bulk', {
            bulk: scheduleDeliverymenu,
          });
        }

        message.success('Success create schedule');
      }

      history.push('/schedule');
    } catch (error) {
      console.error('failed submit ', error);
    } finally {
      setIsLoadingSubmit(false);
    }
  }

  const handleRemoveSchedule = async () => {
    setIsLoadingSubmit(true);
    try {
      await httpRequest.delete<any>(
        '/schedule-delivery-menus/' +
          currentScheduleDelivery?.scheduleDeliveryId
      );
      await httpRequest.delete<any>(
        '/schedule-deliveries/' + currentScheduleDelivery?.scheduleDeliveryId
      );
      await httpRequest.delete<any>('/schedules/' + scheduleAt);
      message.success('Success delete schedule');
      history.push('/schedule');
    } catch (error) {
      console.error('failed remove schedule');
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  const isDisabledSubmit = () => {
    const found = dataSchedules.filter((item) => item.isDelivery && item);
    if (found.length > 0 && selectedDeliveryCoverageTmp) {
      return false;
    } else if (found.length > 0 && !selectedDeliveryCoverageTmp) {
      return true;
    } else if (found.length === 0 && selectedDeliveryCoverageTmp) {
      return true;
    } else {
      return false;
    }
  };

  const [searchValue] = useDebounce(search, 500);

  async function getCurrentSchedule() {
    setIsLoadingCurrentSchedule(true);
    try {
      const res = await httpRequest.get<
        BaseResponsePaginationProps<ScheduleProps>
      >('schedules' + generateQueryString({ scheduleAt }));

      const resDeliveries = await getCurrentScheduleDelivery();
      console.log('resDeliveries', resDeliveries);

      let currentDataSchedules: any[] = [];

      if (res && res.data && res.data.payload && res.data.payload.count > 0) {
        setIsEdit(true);

        currentDataSchedules = res.data.payload.results
          .reduce((acc, curr: ScheduleProps) => {
            const findIndex = acc.findIndex(
              (item) => item.menuId === curr.menuId
            );
            if (findIndex > -1) {
              acc[findIndex]?.availabilityLocations.push({
                locationId: curr.locationId,
                locationName: curr.location?.locationName,
                locationCode: curr.location?.locationCode,
                isSelected: true,
              });
            } else {
              acc.push({
                menuId: curr.menuId,
                menuName: curr.menu?.name,
                categoryName: curr.menu?.category
                  ? curr.menu?.category.categoryName
                  : '-',
                isDelivery: false,
                isCheckAllLocation: false,
                isLocationIndeterminate: false,
                availabilityLocations: [
                  {
                    locationId: curr.locationId,
                    locationName: curr.location?.locationName,
                    locationCode: curr.location?.locationCode,
                    isSelected: true,
                  },
                ],
              });
            }

            return acc;
          }, [] as Array<any>)
          .map((item) => {
            const availabilityLocations = allLocations.map((a) => {
              const found = item.availabilityLocations.find(
                (b: any) => a.locationId === b.locationId
              );

              if (found) {
                return {
                  ...found,
                };
              } else {
                return {
                  locationId: a.locationId,
                  locationName: a.locationName,
                  locationCode: a.locationCode,
                  isSelected: false,
                };
              }
            });

            const dataLocationSelected = availabilityLocations.filter(
              (item) => item.isSelected
            );

            let foundDelivery;

            if (resDeliveries) {
              foundDelivery = resDeliveries.find(
                (i) => i.menuId === item.menuId
              );
            }

            return {
              ...item,
              id: foundDelivery?.id,
              isDelivery: foundDelivery ? true : false,
              isCheckAllLocation:
                dataLocationSelected.length === allLocations.length,
              isLocationIndeterminate:
                dataLocationSelected.length < allLocations.length,
              availabilityLocations,
            };
          });
      }

      if (resDeliveries && resDeliveries.length > 0) {
        setIsEdit(true);
        for (const item of resDeliveries) {
          currentDataSchedules.push({
            menuId: item.menuId,
            menuName: item.menu!.name,
            categoryName: item.menu!.category
              ? item.menu?.category.categoryName
              : '-',
            isDelivery: true,
            isCheckAllLocation: false,
            isLocationIndeterminate: false,
            availabilityLocations: allLocations.map((loc) => ({
              locationId: loc.locationId,
              locationName: loc.locationName,
              locationCode: loc.locationCode,
              isSelected: false,
            })),
          });
        }
      }

      const newData: DataRowsType[] = [];

      for (const item of allMenus) {
        const found = currentDataSchedules.find(
          (a) => a.menuId === item.menuId
        );

        if (found) {
          newData.push(found);
        } else {
          newData.push({
            id: undefined,
            menuId: item.menuId || '',
            menuName: item.name,
            categoryName: item.category ? item.category.categoryName : '-',
            isDelivery: false,
            isCheckAllLocation: false,
            isPublishedMenu: item.isPublished,
            isPublishedCategory: item.category?.isPublished
              ? item.category.isPublished
              : false,
            availabilityLocations: allLocations.map((item) => ({
              locationId: item.locationId,
              locationName: item.locationName,
              locationCode: item.locationCode,
              isSelected: false,
            })),
            isLocationIndeterminate: false,
          });
        }
      }

      mapDataSchedules(newData);

      const isAllLocationSelected = currentDataSchedules.filter(
        (item) => item.isCheckAllLocation
      );

      const isAllDeliverySelected = currentDataSchedules.filter(
        (item) => item.isDelivery
      );
      console.log('isAllLocationSelected', isAllLocationSelected);

      if (isAllLocationSelected.length === 0) {
        setIsSelectAllPickupLocation(false);
        setIsIndeterminatePickupLocation(false);
      } else if (
        isAllLocationSelected.length > 0 &&
        isAllLocationSelected.length !== allMenus.length
      ) {
        setIsIndeterminatePickupLocation(true);
      } else {
        setIsSelectAllPickupLocation(true);
        setIsIndeterminatePickupLocation(false);
      }

      if (isAllDeliverySelected.length === 0) {
        setIsSelectAllDelivery(false);
        setIsIndeterminateDelivery(false);
      } else if (
        isAllDeliverySelected.length > 0 &&
        isAllDeliverySelected.length !== allMenus.length
      ) {
        setIsIndeterminateDelivery(true);
      } else {
        setIsSelectAllDelivery(true);
        setIsIndeterminateDelivery(false);
      }
    } catch (error) {
      console.error('failed get current schedule ', error);
    } finally {
      setIsLoadingCurrentSchedule(false);
    }
  }

  async function getCurrentScheduleDelivery() {
    setIsLoadingScheduleDelivery(true);
    try {
      const res = await httpRequest.get<
        BaseResponsePaginationProps<ScheduleDeliveryProperties>
      >('schedule-deliveries' + generateQueryString({ scheduleAt }));

      if (
        res &&
        res.data &&
        res.data.payload &&
        res.data.payload.results.length > 0
      ) {
        const scheduleDelivery = res.data.payload.results[0];
        const startAt = moment(scheduleDelivery.deliveryRangeStartAt).format(
          'YYYY-MM-DD'
        );
        const endAt = moment(scheduleDelivery.deliveryRangeEndAt).format(
          'YYYY-MM-DD'
        );

        setDateRange([startAt, endAt]);

        setCurrentScheduleDelivery(scheduleDelivery);

        scheduleDelivery.deliveryCoverage &&
          setSelectedDeliveryCoverageTmp(scheduleDelivery.deliveryCoverage.id);

        return scheduleDelivery.schedule_delivery_menus || [];
      } else {
        setDateRange([scheduleAt, scheduleAt]);

        return [];
      }
    } catch (error) {
      console.error('failed get current schedule delivery ', error);
    } finally {
      setIsLoadingScheduleDelivery(false);
    }
  }

  async function getAllMenu(params?: { search: string }) {
    setIsLoadingFetchAllMenu(true);
    try {
      const res = await httpRequest.get<BaseResponsePaginationProps<MenuProps>>(
        'menus' + generateQueryString({ ...params, limit: 1000 })
      );
      if (res && res.data.payload) {
        setAllMenus(res.data.payload.results);
      }
      console.log(res);
    } catch (error) {
      console.error('failed get all menu :', error);
    } finally {
      setIsLoadingFetchAllMenu(false);
    }
  }

  async function getAllLocation() {
    setIsLoadingFetchAllMenu(true);
    try {
      const res = await httpRequest.get<
        BaseResponsePaginationProps<LocationProps>
      >('locations' + generateQueryString({ isPublished: 'active' }));
      if (res && res.data.payload) {
        setAllLocations(res.data.payload.results);
      }
    } catch (error) {
      console.error('failed get all locations :', error);
    } finally {
      setIsLoadingFetchAllMenu(false);
    }
  }

  async function getAllDeliveryCoverage() {
    setIsLoadingDeliveryCoverage(true);
    try {
      const res = await httpRequest.get<
        BaseResponsePaginationProps<DeliveryCoverageProperties>
      >('delivery-coverages');
      if (res && res.data.payload) {
        setAllDeliveryCoverages(res.data.payload.results);
        setAllDeliveryCoveragesTmp(
          res.data.payload.results.map((item) => ({
            label: item.coverageName,
            value: item.id,
          }))
        );
      }
    } catch (error) {
      console.error('failed get all delivery coverage :', error);
    } finally {
      setIsLoadingDeliveryCoverage(false);
    }
  }

  function mapDataSchedules(currentData?: DataRowsType[]) {
    let newDatas: DataRowsType[] = [];

    if (currentData) {
      newDatas = currentData;
    } else {
      newDatas = allMenus.map((item) => {
        return {
          menuId: item.menuId || '',
          menuName: item.name,
          categoryName: item.category ? item.category.categoryName : '-',
          isDelivery: false,
          isCheckAllLocation: false,
          isLocationIndeterminate: false,
          isPublishedMenu: item.isPublished,
          isPublishedCategory: item.category?.isPublished
            ? item.category.isPublished
            : false,
          availabilityLocations: allLocations.map((loc) => ({
            locationId: loc.locationId,
            locationName: loc.locationName,
            locationCode: loc.locationCode,
            isSelected: false,
          })),
        };
      });
    }

    setDataSchedules(newDatas);
  }

  function handleSelectLocation(e: SelectLocationProps) {
    const newDataSchedules = dataSchedules.map((item) => {
      if (item.menuId === e.selectedMenuId) {
        let newMenuData: DataRowsType;
        newMenuData = {
          ...item,
          availabilityLocations: item.availabilityLocations.map((loc2) =>
            loc2.locationId === e.locationId
              ? { ...loc2, isSelected: !loc2.isSelected }
              : { ...loc2 }
          ),
        };

        let foundUncheck = newMenuData.availabilityLocations.filter(
          (loc) => !loc.isSelected
        );

        if (foundUncheck.length === item.availabilityLocations.length) {
          newMenuData = {
            ...newMenuData,
            isCheckAllLocation: false,
            isLocationIndeterminate: false,
          };
        } else if (foundUncheck.length > 0) {
          newMenuData = {
            ...newMenuData,
            isCheckAllLocation: false,
            isLocationIndeterminate: true,
          };
        } else if (foundUncheck.length === 0) {
          newMenuData = {
            ...newMenuData,
            isCheckAllLocation: true,
            isLocationIndeterminate: false,
          };
        }

        return { ...item, ...newMenuData };
      } else {
        return { ...item };
      }
    });

    const dataSelected = newDataSchedules.filter(
      (item) => item.isCheckAllLocation && item
    );

    if (dataSelected.length === 0) {
      setIsSelectAllPickupLocation(false);
      setIsIndeterminatePickupLocation(true);
    } else if (dataSelected.length !== newDataSchedules.length) {
      setIsIndeterminatePickupLocation(true);
    } else {
      setIsSelectAllPickupLocation(true);
      setIsIndeterminatePickupLocation(false);
    }

    setDataSchedules(newDataSchedules);
  }

  function handleSelectDelivery(e: SelectSingleRowActionProps) {
    const newDataSchedules = dataSchedules.map((item) => {
      if (item.menuId === e.menuId) {
        return { ...item, isDelivery: e.checked };
      } else {
        return { ...item };
      }
    });

    const dataSelected = newDataSchedules.filter((item) => item.isDelivery);

    if (dataSelected.length === 0) {
      setIsSelectAllDelivery(false);
      setIsIndeterminateDelivery(false);
    } else if (dataSelected.length !== newDataSchedules.length) {
      setIsIndeterminateDelivery(true);
    } else {
      setIsSelectAllDelivery(true);
      setIsIndeterminateDelivery(false);
    }

    setDataSchedules(newDataSchedules);
  }

  function handleSelectDeliveryCoverage(deliveryCoverageId: string) {
    setSelectedDeliveryCoverageTmp(deliveryCoverageId);
  }

  function handleSelectAllRowLocation(e: SelectSingleRowActionProps) {
    const newDataSchedules = dataSchedules.map((item) => {
      if (item.menuId === e.menuId) {
        return {
          ...item,
          isCheckAllLocation: e.checked,
          isLocationIndeterminate: false,
          availabilityLocations: item.availabilityLocations.map((loc) => ({
            ...loc,
            isSelected: e.checked,
          })),
        };
      } else {
        return { ...item };
      }
    });

    const dataSelected = newDataSchedules.filter(
      (item) => item.isCheckAllLocation || item.isLocationIndeterminate
    );

    if (dataSelected.length === 0) {
      setIsSelectAllPickupLocation(false);
      setIsIndeterminatePickupLocation(false);
    } else if (dataSelected.length !== newDataSchedules.length) {
      setIsIndeterminatePickupLocation(true);
    } else {
      setIsSelectAllPickupLocation(true);
      setIsIndeterminatePickupLocation(false);
    }

    setDataSchedules(newDataSchedules);
  }

  function handleSelectAllDelivery(checked: boolean) {
    setIsSelectAllDelivery(checked);
    setIsIndeterminateDelivery(false);
  }

  function handleSelectAllPickupLocation(checked: boolean) {
    setIsSelectAllPickupLocation(checked);
    setIsIndeterminatePickupLocation(false);
  }

  useEffect(() => {
    setDataSchedules(
      dataSchedules.map((item) => ({
        ...item,
        isDelivery:
          item.isPublishedMenu == false || item.isPublishedCategory == false
            ? false
            : isSelectAllDelivery,
      }))
    );
  }, [isSelectAllDelivery]);

  useEffect(() => {
    setDataSchedules(
      dataSchedules.map((items) => ({
        ...items,
        isCheckAllLocation:
          items.isPublishedMenu == false || items.isPublishedCategory == false
            ? false
            : isSelectAllPickupLocation,
        isLocationIndeterminate: false,
        availabilityLocations: items.availabilityLocations.map((item) => ({
          ...item,
          isSelected:
            items.isPublishedMenu == false || items.isPublishedCategory == false
              ? false
              : isSelectAllPickupLocation,
        })),
      }))
    );
  }, [isSelectAllPickupLocation]);

  useEffect(() => {
    getAllMenu({ search: searchValue });
  }, [searchValue]);

  useEffect(() => {
    getAllMenu();
    getAllLocation();
    getAllDeliveryCoverage();
  }, [scheduleAt]);

  useEffect(() => {
    if (allMenus.length > 0 && allLocations.length > 0) {
      mapDataSchedules();
      getCurrentSchedule();
    }
  }, [allMenus, allLocations]);

  return (
    <AppLayout>
      <HeaderSection
        icon={<AppstoreAddOutlined />}
        title={`${isDetail ? 'Detail' : 'Edit'} Schedule for ${moment(
          scheduleAt
        ).format('ddd, DD MMM YYYY')}`}
        rightAction={
          !isDetail ? (
            <Space>
              {isEdit && (
                <AppButton
                  danger
                  disabled={isLoadingSubmit}
                  loading={isLoadingSubmit}
                  onClick={() => setOpenConfirmDelete(true)}
                  icon={<DeleteOutlined />}
                >
                  Delete Schedule
                </AppButton>
              )}
              <AppButton onClick={() => history.goBack()}>Cancel</AppButton>
              <AppButton
                disabled={isLoadingSubmit || isDisabledSubmit()}
                loading={isLoadingSubmit}
                type='primary'
                onClick={() => handleSubmit()}
              >
                {isEdit ? 'Update' : 'Save'}
              </AppButton>
            </Space>
          ) : (
            <></>
          )
        }
      />

      <Modal
        title='Delete Confirmation'
        visible={openConfirmDelete}
        onOk={handleRemoveSchedule}
        okText='Yes, Sure'
        confirmLoading={isLoadingSubmit}
        onCancel={() => setOpenConfirmDelete(false)}
        maskClosable={false}
      >
        <p>
          Are you sure want to delete this schedule? this action cannot be
          undone.
        </p>
      </Modal>

      <div className='schedule-card'>
        <div className='schedule-title'>Delivery Configuration</div>
        <Form layout='vertical'>
          <Row style={{ gap: 20 }}>
            <Form.Item label='Delivery range' style={{ flex: 1 }}>
              {dateRange[0] && dateRange[1] && (
                <RangePicker
                  picker='date'
                  style={{ width: '100%' }}
                  defaultValue={[
                    moment(dateRange[0], 'YYYY-MM-DD'),
                    moment(dateRange[1], 'YYYY-MM-DD'),
                  ]}
                  // defaultValue={[dateRange[0], dateRange[1]]}
                  onChange={(_, formatString: [string, string]) =>
                    setDateRange([formatString[0], formatString[1]])
                  }
                  disabled={!isDetail ? [true, false] : [true, true]}
                  format={dateFormat}
                />
              )}
            </Form.Item>
            <Form.Item label='Delivery Coverage' style={{ flex: 1 }}>
              <Select
                disabled={isDetail}
                loading={isLoadingDeliveryCoverage}
                defaultValue={selectedDeliveryCoverageTmp}
                value={selectedDeliveryCoverageTmp}
                style={{ width: '100%' }}
                onChange={handleSelectDeliveryCoverage}
                placeholder='Choose delivery coverage'
                options={allDeliveryCoveragesTmp}
              />
            </Form.Item>
          </Row>
        </Form>

        <div className='schedule-title'>Available Menu</div>
        <Spin
          tip='Loading...'
          spinning={isLoadingFetchAllMenu || isLoadingCurrentSchedule}
        >
          <ContainerFilter>
            <Input
              size='large'
              placeholder='Search by Name'
              prefix={<SearchOutlined />}
              allowClear
              onChange={(e) => setSearch(e.target.value)}
            />
          </ContainerFilter>

          <TableHeader
            isDisabled={isDetail}
            isIndeterminateDelivery={isIndeterminateDelivery}
            isSelectAllDelivery={isSelectAllDelivery}
            isIndeterminatePickupLocation={isIndeterminatePickupLocation}
            isSelectAllPickupLocation={isSelectAllPickupLocation}
            selectAllDelivery={(e) => handleSelectAllDelivery(e.target.checked)}
            selectAllPickupLocation={(e) =>
              handleSelectAllPickupLocation(e.target.checked)
            }
          />

          <TableRows
            isDisabled={isDetail}
            data={dataSchedules}
            selectLocation={(e) => handleSelectLocation(e)}
            selectDelivery={(e) => handleSelectDelivery(e)}
            selectAllRowLocation={(e) => handleSelectAllRowLocation(e)}
          />
        </Spin>
      </div>
    </AppLayout>
  );
};

type DataLocationType = {
  locationId: string;
  locationName: string;
  locationCode: string;
  isSelected: boolean;
};

type DataRowsType = {
  id?: string;
  menuId: string;
  menuName: string;
  categoryName: string;
  isDelivery: boolean;
  isCheckAllLocation: boolean;
  availabilityLocations: DataLocationType[];
  isLocationIndeterminate?: boolean;
  isPublishedMenu: boolean;
  isPublishedCategory: boolean;
};

interface SelectLocationProps extends DataLocationType {
  selectedMenuId: string;
}

interface SelectSingleRowActionProps {
  checked: boolean;
  menuId: string;
}

type TableRowsType = {
  data: DataRowsType[];
  selectLocation: (e: SelectLocationProps) => void;
  selectDelivery: (e: SelectSingleRowActionProps) => void;
  selectAllRowLocation: (e: SelectSingleRowActionProps) => void;
  isDisabled?: boolean;
};

const TableRows: React.FC<TableRowsType> = (props) => {
  return (
    <>
      {props.data.map((item, idx) => (
        <div
          className='schedule-table-row'
          style={{
            background:
              item.isPublishedMenu == false || item.isPublishedCategory == false
                ? '#F1F3F5'
                : '',
          }}
          key={idx}
        >
          <div
            style={{
              flex: 0.1,
              opacity:
                item.isPublishedMenu == false ||
                item.isPublishedCategory == false
                  ? 0.8
                  : 1,
            }}
          >
            {idx + 1}
          </div>
          <div
            style={{
              flex: 2,
              opacity:
                item.isPublishedMenu == false ||
                item.isPublishedCategory == false
                  ? 0.8
                  : 1,
            }}
          >
            {item.menuName}
            {item.isPublishedMenu == false ||
            item.isPublishedCategory == false ? (
              <Tooltip
                title={
                  item.isPublishedCategory == false &&
                  item.isPublishedMenu == false
                    ? 'Menu is not active because the menu and category status are not published'
                    : item.isPublishedMenu == false
                    ? 'The menu is not active because the menu status is not published'
                    : 'The menu is not active because the category status is not published'
                }
              >
                <Tag style={{ marginLeft: '5px' }} color='#A5B2BD'>
                  Menu Inactive
                </Tag>
              </Tooltip>
            ) : (
              <></>
            )}
          </div>
          <div
            style={{
              flex: 0.3,
              opacity:
                item.isPublishedMenu == false ||
                item.isPublishedCategory == false
                  ? 0.8
                  : 1,
            }}
          >
            {item.categoryName || '-'}
          </div>
          <div
            style={{
              flex: 0.3,
              opacity:
                item.isPublishedMenu == false ||
                item.isPublishedCategory == false
                  ? 0 / 8
                  : 1,
            }}
          >
            <Checkbox
              disabled={
                props.isDisabled
                  ? props.isDisabled
                  : item.isPublishedMenu == false ||
                    item.isPublishedCategory == false
                  ? true
                  : false
              }
              checked={item.isDelivery}
              onChange={(e) =>
                props.selectDelivery({
                  checked: e.target.checked,
                  menuId: item.menuId,
                })
              }
            />
          </div>
          <div className='schedule-location-list'>
            <Checkbox
              disabled={
                props.isDisabled
                  ? props.isDisabled
                  : item.isPublishedMenu == false ||
                    item.isPublishedCategory == false
                  ? true
                  : false
              }
              indeterminate={item.isLocationIndeterminate}
              checked={item.isCheckAllLocation}
              onChange={(e) =>
                props.selectAllRowLocation({
                  checked: e.target.checked,
                  menuId: item.menuId,
                })
              }
              key={idx}
            />
            <div className='schedule-location-list'>
              {item.availabilityLocations.map((loc, idx) => (
                <span
                  key={idx}
                  onClick={() =>
                    !props.isDisabled &&
                    props.selectLocation({
                      ...loc,
                      selectedMenuId: item.menuId,
                    })
                  }
                  title={loc.locationName}
                  className={
                    loc.isSelected
                      ? 'schedule-location-code schedule-location-code-active'
                      : 'schedule-location-code'
                  }
                  style={{ cursor: props.isDisabled ? 'default' : 'pointer' }}
                >
                  {loc.locationCode}
                </span>
              ))}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

type TableHeaderType = {
  isSelectAllDelivery: boolean;
  isIndeterminateDelivery: boolean;
  selectAllDelivery: (e: CheckboxChangeEvent) => void;
  isSelectAllPickupLocation: boolean;
  isIndeterminatePickupLocation: boolean;
  selectAllPickupLocation: (e: CheckboxChangeEvent) => void;
  isDisabled?: boolean;
};

const TableHeader: React.FC<TableHeaderType> = (props) => {
  return (
    <div className='schedule-table-header'>
      <div style={{ flex: 0.1 }}>#</div>
      <div style={{ flex: 2 }}>Name</div>
      <div style={{ flex: 0.3 }}>Category</div>
      <div style={{ flex: 0.3 }}>
        <Checkbox
          disabled={props.isDisabled}
          indeterminate={props.isIndeterminateDelivery}
          checked={props.isSelectAllDelivery}
          onChange={props.selectAllDelivery}
        >
          Delivery
        </Checkbox>
      </div>
      <div>
        <Checkbox
          disabled={props.isDisabled}
          indeterminate={props.isIndeterminatePickupLocation}
          checked={props.isSelectAllPickupLocation}
          onChange={props.selectAllPickupLocation}
        >
          Pickup Location
        </Checkbox>
      </div>
    </div>
  );
};

export default EditSchedule2;
